import React from 'react';
import AboutMe from './components/AboutMe';

function App() {
  return (
    <div className="min-h-screen bg-[#1D2433] flex items-center justify-center">
      <AboutMe />
    </div>
  );
}

export default App;
